import { request, ResponseBody } from "@tager/admin-services";

import { AmpLocationInterface, ApiAmpLocationType } from "./typings";

export interface CountData {
  count: number;
}

export function getAmpLocationsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/amp-locations/count" });
}

export function getAmpLocations(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<AmpLocationInterface>>> {
  return request.get({ path: "/admin/amp-locations", params });
}

export function getAmpLocation(
  id: string | number
): Promise<ResponseBody<AmpLocationInterface>> {
  return request.get({ path: `/admin/amp-locations/${id}` });
}

export type AmpLocationCreateOrUpdatePayload = {
  name: string;
  phone: string;
  address: string;
  longitude: number | null;
  latitude: number | null;
  apiIds: number[];
};

export function createAmpLocation(
  payload: AmpLocationCreateOrUpdatePayload
): Promise<ResponseBody<AmpLocationInterface>> {
  return request.post({
    path: `/admin/amp-locations`,
    body: payload,
  });
}

export function updateAmpLocation(
  id: number | string,
  payload: AmpLocationCreateOrUpdatePayload
): Promise<ResponseBody<AmpLocationInterface>> {
  return request.put({
    path: `/admin/amp-locations/${id}`,
    body: payload,
  });
}

export function deleteAmpLocation(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/amp-locations/${id}` });
}

export function moveAmpLocation(
  id: number | string,
  direction: "up" | "down"
): Promise<{ success: boolean }> {
  return request.post({ path: `/admin/amp-locations/${id}/move/${direction}` });
}

export function getApiAmpLocations(): Promise<
  ResponseBody<Array<ApiAmpLocationType>>
> {
  return request.get({ path: "/admin/amp-locations/api-locations" });
}
