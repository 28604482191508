import { request, ResponseBody } from "@tager/admin-services";

import { CarsSyncSessionInterface } from "./typings";

export function getCarsSyncSessions(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
}): Promise<ResponseBody<Array<CarsSyncSessionInterface>>> {
  return request.get({ path: "/admin/cars/sync-sessions", params });
}
