import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";

import SyncSessionsList from "./SyncSessionsList";

export function getCarsSyncSessionsUrl(): string {
  return "/cars/sync-sessions";
}

export const CARS_SYNC_SESSIONS_LIST_ROUTE: RouteRecordRaw = {
  path: getCarsSyncSessionsUrl(),
  component: SyncSessionsList,
  name: "Sync Sessions List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().CARS_SYNC_SESSIONS],
  },
};
