
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFieldCheckbox,
  TagerFormSubmitEvent,
  FormFooter,
  FormFieldSelect,
} from "@tager/admin-ui";

import { useGifts } from "@/modules/gifts/hooks";

import { updateNewCar, getCarNew } from "../requests";
import { getNewCarsUrl } from "../routes";

import {
  convertPageFormValuesToCreationOrUpdatePayload,
  FormValues,
  getNewCarsFormValues,
} from "./NewCarsForm.helpers";

export default defineComponent({
  name: "AmpCarsForm",
  components: {
    FormFieldSelect,
    Page,
    FormFieldCheckbox,
    FormFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getCarNew(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "AMP Car",
    });

    onMounted(() => {
      fetchModel();
    });
    watch(id, fetchModel);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getNewCarsFormValues(model.value));

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getNewCarsFormValues(model.value);
    }

    onMounted(updateFormValues);
    watch(model, updateFormValues);

    const { options: giftsOptions, loading: isGiftsOptionsLoading } =
      useGifts();

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(values.value);

      const requestPromise = updateNewCar(
        parseInt(id?.value || ""),
        createOrUpdatePayload
      );

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "save_exit") {
            navigateBack(router, getNewCarsUrl());
          }

          toast.show({
            variant: "success",
            title: "Машины",
            body: "Автомобиль успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Машины",
            body: "Ошибка изменения автомобиля",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isModelLoading.value || isGiftsOptionsLoading.value
    );

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      isCreation,
      submitForm,
      backButtonUrl: getNewCarsUrl(),
      isSubmitting,
      giftsOptions,
    };
  },
});
