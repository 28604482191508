import { request, ResponseBody } from "@tager/admin-services";

import {
  PartnerCarCreateOrUpdatePayload,
  PartnerCarFullInterface,
} from "@/modules/cars/partner/typings";

import {
  NewCarFullInterface,
  NewCarInterface,
  NewCarSavePayload,
} from "./typings";

export interface CountData {
  count: number;
}

export function getCarsNewCount(): Promise<ResponseBody<CountData>> {
  return request.get({
    path: "/admin/cars/new/count",
    params: { type: "new" },
  });
}

export function getCarsNew(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<NewCarInterface>>> {
  return request.get({ path: "/admin/cars/new", params });
}

export function getCarNew(
  id: string | number
): Promise<ResponseBody<NewCarFullInterface>> {
  return request.get({ path: `/admin/cars/new/${id}` });
}

export function updateNewCar(
  id: number,
  payload: NewCarSavePayload
): Promise<ResponseBody<NewCarFullInterface>> {
  return request.put({
    path: `/admin/cars/new/${id}`,
    body: payload,
  });
}
