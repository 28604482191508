import { OptionType } from "@tager/admin-ui";

export const statusOptions: Array<OptionType> = [
  { value: "AVAILABLE", label: "Свободен" },
  { value: "BOOKED", label: "Забронирован" },
  { value: "SOLD", label: "Продан" },
];

export function getStatusLabel(status: string): string {
  return (
    statusOptions.find((item) => item.value === status)?.label || "Неизвестный"
  );
}
