import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import AmpCarsForm from "@/modules/cars/amp/AmpCarsForm";

import AmpCarsList from "./AmpCarsList";

export function getAmpCarsUrl(): string {
  return "/cars/amp";
}

export function getAmpCarsUpdateUrl(id: number): string {
  return "/cars/amp/" + id;
}

export const AMP_CARS_LIST_ROUTE: RouteRecordRaw = {
  path: "/cars/amp",
  component: AmpCarsList,
  name: "Amp Cars List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().CARS_AMP],
  },
};

export const AMP_CARS_FORM_ROUTE: RouteRecordRaw = {
  path: "/cars/amp/:id",
  component: AmpCarsForm,
  name: "Amp Car Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().CARS_AMP,
      { url: "", text: "Автомобиль c пробегом" },
    ],
  },
};
