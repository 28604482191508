
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGrid, NavigationGridItem } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { getLinks } from "@/constants/links";
import { getCarsAmpCount } from "@/modules/cars/amp";
import { getCarsNewCount } from "@/modules/cars/new";
import { getPartnerCarsCount } from "@/modules/cars/partner";
import { getAmpLocationsCount } from "@/modules/ampLocations";
import { getColorsCount } from "@/modules/colors";
import { getDealersCount } from "@/modules/dealers";
import { getGiftsCount } from "@/modules/gifts";

export default defineComponent({
  name: "PageHome",
  components: { NavigationGrid },
  setup() {
    const links = computed(() => getLinks());

    const [
      fetchCarsNewCount,
      { data: carsNewCountData, status: carsNewCountDataStatus },
    ] = useResource({
      fetchResource: () => getCarsNewCount(),
      resourceName: "Количество новых машин",
      initialValue: null,
    });

    const [
      fetchCarsAmpCount,
      { data: carsAmpCountData, status: carsAmpCountDataStatus },
    ] = useResource({
      fetchResource: () => getCarsAmpCount(),
      resourceName: "Количество бу машин",
      initialValue: null,
    });

    const [
      fetchCarsPartnerCount,
      { data: carsPartnerCountData, status: carsPartnerCountDataStatus },
    ] = useResource({
      fetchResource: () => getPartnerCarsCount(),
      resourceName: "Количество бу машин от партнеров",
      initialValue: null,
    });

    const [
      fetchColorsCount,
      { data: colorsCountData, status: colorsCountDataStatus },
    ] = useResource({
      fetchResource: () => getColorsCount(),
      resourceName: "Количество цветов",
      initialValue: null,
    });

    const [
      fetchGiftsCount,
      { data: giftsCountData, status: giftsCountDataStatus },
    ] = useResource({
      fetchResource: () => getGiftsCount(),
      resourceName: "Количество подарков",
      initialValue: null,
    });

    const [
      fetchAmpLocationsCount,
      { data: ampLocationsCountData, status: ampLocationsCountDataStatus },
    ] = useResource({
      fetchResource: () => getAmpLocationsCount(),
      resourceName: "Количество локаций",
      initialValue: null,
    });

    const [
      fetchDealersCount,
      { data: dealersCountData, status: dealersCountDataStatus },
    ] = useResource({
      fetchResource: () => getDealersCount(),
      resourceName: "Количество дилеров",
      initialValue: null,
    });

    onMounted(() => {
      fetchCarsNewCount();
      fetchCarsAmpCount();
      fetchCarsPartnerCount();
      fetchAmpLocationsCount();
      fetchColorsCount();
      fetchDealersCount();
      fetchGiftsCount();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.CARS_NEW.text,
          url: links.value.CARS_NEW.url,
          total: {
            status: carsNewCountDataStatus.value,
            value: carsNewCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.CARS_AMP.text,
          url: links.value.CARS_AMP.url,
          total: {
            status: carsAmpCountDataStatus.value,
            value: carsAmpCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.CARS_PARTNERS.text,
          url: links.value.CARS_PARTNERS.url,
          total: {
            status: carsPartnerCountDataStatus.value,
            value: carsPartnerCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.DEALERS.text,
          url: links.value.DEALERS.url,
          total: {
            status: dealersCountDataStatus.value,
            value: dealersCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.AMP_LOCATIONS.text,
          url: links.value.AMP_LOCATIONS.url,
          total: {
            status: ampLocationsCountDataStatus.value,
            value: ampLocationsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.GIFTS.text,
          url: links.value.GIFTS.url,
          total: {
            status: giftsCountDataStatus.value,
            value: giftsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.COLORS.text,
          url: links.value.COLORS.url,
          total: {
            status: colorsCountDataStatus.value,
            value: colorsCountData.value?.count ?? 0,
          },
          width: 33.3333,
        },
        {
          name: links.value.SETTINGS_COMMON.text,
          url: links.value.SETTINGS_COMMON.url,
          width: 33.3333,
        },
        {
          name: "Команды",
          linkList: [
            links.value.CRON_COMMANDS,
            links.value.CRON_COMMANDS_LOGS,
            links.value.CRON_LOGS,
          ],
          width: 33.3333,
        },
        {
          name: "Администраторы",
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
          width: 33.3333,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
