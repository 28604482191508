
import { defineComponent } from "vue";

import { useDataTable, DataTable, BaseButton, EditIcon } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getDealersUpdateUrl } from "@/modules/dealers/routes";

import { getDealers } from "../requests";
import { DealerInterface } from "../typings";

import { COLUMN_DEFS } from "./DealersList.helpers";

export default defineComponent({
  name: "DealersList",
  components: {
    Page,
    DataTable,
    BaseButton,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<DealerInterface>({
      fetchEntityList: (params) =>
        getDealers({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список локаций",
    });

    function isBusy(id: number): boolean {
      return isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      isBusy,

      getDealersUpdateUrl,
    };
  },
});
