import { request, ResponseBody } from "@tager/admin-services";

import {
  PartnerCarParams,
  PartnerCarInterface,
  PartnerCarFullInterface,
  PartnerCarCreateOrUpdatePayload,
} from "./typings";

export interface CountData {
  count: number;
}

export function getPartnerCarsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/cars/partner/count" });
}

export function getPartnerCars(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
}): Promise<ResponseBody<Array<PartnerCarInterface>>> {
  return request.get({ path: "/admin/cars/partner", params });
}

export function getPartnerCarsParams(): Promise<
  ResponseBody<PartnerCarParams>
> {
  return request.get({ path: "/admin/cars/partner/params" });
}

export function getPartnerCar(
  id: string | number
): Promise<ResponseBody<PartnerCarFullInterface>> {
  return request.get({ path: `/admin/cars/partner/${id}` });
}

export function createPartnerCar(
  payload: PartnerCarCreateOrUpdatePayload
): Promise<ResponseBody<PartnerCarFullInterface>> {
  return request.post({
    path: `/admin/cars/partner`,
    body: payload,
  });
}

export function updatePartnerCar(
  id: number,
  payload: PartnerCarCreateOrUpdatePayload
): Promise<ResponseBody<PartnerCarFullInterface>> {
  return request.put({
    path: `/admin/cars/partner/${id}`,
    body: payload,
  });
}

export function deletePartnerCar(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/cars/partner/${id}` });
}

export function clonePartnerCar(id: number | string): Promise<{ data: any }> {
  return request.post({ path: `/admin/cars/partner/${id}/clone` });
}
