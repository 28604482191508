import { request, ResponseBody } from "@tager/admin-services";

import {
  NewCarFullInterface,
  NewCarSavePayload,
} from "@/modules/cars/new/typings";

import {
  AmpCarFullInterface,
  AmpCarInterface,
  AmpCarSavePayload,
} from "./typings";

export interface CountData {
  count: number;
}

export function getCarsAmpCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/cars/amp/count" });
}

export function getCarsAmp(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
}): Promise<ResponseBody<Array<AmpCarInterface>>> {
  return request.get({ path: "/admin/cars/amp", params });
}

export function getAmpCar(
  id: string | number
): Promise<ResponseBody<AmpCarFullInterface>> {
  return request.get({ path: `/admin/cars/amp/${id}` });
}

export function updateAmpCar(
  id: number,
  payload: AmpCarSavePayload
): Promise<ResponseBody<AmpCarFullInterface>> {
  return request.put({
    path: `/admin/cars/amp/${id}`,
    body: payload,
  });
}
