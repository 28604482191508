import { ColumnDefinition } from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

import { getTypeLabel } from "@/modules/cars/partner/constants";

import { NewCarInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<NewCarInterface>> = [
  {
    id: 1,
    name: "API ID",
    field: "apiId",
    type: "link",
    format: ({ row }) => ({
      url: `${row.url}`,
      text: String(row.apiId),
    }),
    options: {
      shouldOpenNewTab: true,
    },
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Автомобиль",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: `${row.name}`,
        url: null,
      },
      websiteLink: {
        text: row.url,
        url: row.url,
      },
      paramList: [
        row.hidden
          ? {
              name: "Скрыт с сайта",
              value: "Да",
            }
          : null,
      ].filter(isNotNullish),
    }),
  },
  {
    id: 3,
    name: "Год",
    field: "year",
    style: { width: "60px", textAlign: "center" },
    headStyle: { width: "60px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 4,
    name: "VIN",
    field: "vin",
    style: { width: "150px" },
    headStyle: { width: "150px" },
  },
  {
    id: 5,
    name: "Статус",
    field: "status",
    style: { width: "120px" },
    headStyle: { width: "120px" },
  },
  {
    id: 6,
    name: "Цена, BYN",
    field: "price",
    style: { width: "80px", textAlign: "right", whiteSpace: "nowrap" },
    headStyle: { width: "80px", textAlign: "right", whiteSpace: "nowrap" },
  },
  {
    id: 7,
    name: "Действия",
    field: "actions",
    style: { textAlign: "center" },
    headStyle: { width: "150px", textAlign: "center" },
  },
];
