import { request, ResponseBody } from "@tager/admin-services";

import { ColorInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getColorsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/colors/count" });
}

export function getColors(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ColorInterface>>> {
  return request.get({ path: "/admin/colors", params });
}

export function getColor(
  id: string | number
): Promise<ResponseBody<ColorInterface>> {
  return request.get({ path: `/admin/colors/${id}` });
}

export type ColorCreateOrUpdatePayload = {
  name: string;
  hex: string;
};

export function createColor(
  payload: ColorCreateOrUpdatePayload
): Promise<ResponseBody<ColorInterface>> {
  return request.post({
    path: `/admin/colors`,
    body: payload,
  });
}

export function updateColor(
  id: number | string,
  payload: ColorCreateOrUpdatePayload
): Promise<ResponseBody<ColorInterface>> {
  return request.put({
    path: `/admin/colors/${id}`,
    body: payload,
  });
}

export function deleteColor(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/colors/${id}` });
}

export function moveColor(
  id: number | string,
  direction: "up" | "down"
): Promise<{ success: boolean }> {
  return request.post({ path: `/admin/colors/${id}/move/${direction}` });
}
