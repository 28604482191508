
import { defineComponent } from "vue";

import {
  useDataTable,
  DataTable,
  BaseButton,
  CountButton,
  SouthIcon,
  NorthIcon,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import { getAmpCarsUrl } from "@/modules/cars/amp/routes";
import { getPartnerCarsUrl } from "@/modules/cars/partner/routes";
import {
  getAmpLocationsCreateUrl,
  getAmpLocationsUpdateUrl,
} from "@/modules/ampLocations/routes";

import {
  deleteAmpLocation,
  getAmpLocations,
  moveAmpLocation,
} from "../requests";
import { AmpLocationInterface } from "../typings";

import { COLUMN_DEFS } from "./AmpLocationsList.helpers";

export default defineComponent({
  name: "AmpLocationsList",
  components: {
    Page,
    DataTable,
    BaseButton,
    CountButton,
    SouthIcon,
    NorthIcon,
    DeleteIcon,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<AmpLocationInterface>({
      fetchEntityList: (params) =>
        getAmpLocations({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список локаций",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveAmpLocation,
      resourceName: "Локация",
      onSuccess: fetchEntityList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteAmpLocation,
      resourceName: "Локация",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      isBusy,

      handleResourceMove,
      handleResourceDelete,

      getPartnerCarsUrl,
      getAmpCarsUrl,

      getAmpLocationsCreateUrl,
      getAmpLocationsUpdateUrl,
    };
  },
});
