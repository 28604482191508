import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: 'Редактировать Дилера ID ' + _ctx.id,
    "is-content-loading": _ctx.isLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isLoading && _ctx.model)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_FieldValue, {
              label: "API ID",
              value: String(_ctx.model.apiId)
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Название",
              value: _ctx.model.name
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Адрес",
              value: _ctx.model.address
            }, null, 8, ["value"]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_FieldValue, {
                label: "Картинка",
                src: _ctx.model.image,
                type: "image"
              }, null, 8, ["src"])
            ]),
            _createVNode(_component_FormField, {
              value: _ctx.values.phonePublic,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.phonePublic) = $event)),
              name: "phonePublic",
              error: _ctx.errors.phonePublic,
              label: "Телефон, отображаемый на сайте"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormFieldCheckbox, {
              checked: _ctx.values.vinNewHidden,
              "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.vinNewHidden) = $event)),
              name: "vinNewHidden",
              error: _ctx.errors.vinNewHidden,
              label: "Скрыть VIN"
            }, null, 8, ["checked", "error"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}