
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  createTabErrorFinder,
  FormField,
  FormFieldCheckbox,
  FormFieldFileInput,
  FormFieldSelect,
  FormFooter,
  OptionType,
  TabType,
  TagerFormSubmitEvent,
  ToggleSection,
} from "@tager/admin-ui";
import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import { TYPE_OPTIONS } from "@/modules/cars/partner/constants";
import { getGifts } from "@/modules/gifts";
import { useGifts } from "@/modules/gifts/hooks";

import {
  createPartnerCar,
  getPartnerCar,
  getPartnerCarsParams,
  updatePartnerCar,
} from "../requests";
import { getPartnerCarsUpdateUrl, getPartnerCarsUrl } from "../routes";

import {
  convertPageFormValuesToCreationOrUpdatePayload,
  CURRENCY_OPTIONS,
  factoryOptionsFieldConfig,
  FormValues,
  getAmpCarFormValue,
  PRICE_STRATEGY_OPTIONS,
  PriceStrategy,
  PRICING_CHINA_CONTAINER_OPTIONS,
} from "./PartnerCarsForm.helpers";

export default defineComponent({
  name: "AmpCarsForm",
  components: {
    Page,
    FormFieldCheckbox,
    FormField,
    FormFooter,
    FormFieldSelect,
    FormFieldFileInput,
    ToggleSection,
    DynamicField,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getPartnerCar(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "AMP Car",
    });

    const [fetchParams, { data: params, loading: isParamsLoading }] =
      useResource({
        fetchResource: () => getPartnerCarsParams(),
        initialValue: null,
        resourceName: "AMP Params",
      });

    const { options: giftsOptions, loading: isGiftsOptionsLoading } =
      useGifts();

    onMounted(() => {
      fetchParams();
      fetchModel();
    });

    watch(id, fetchModel);

    const brandsOptions = computed<Array<OptionType<number>>>(() => {
      return (
        params.value?.brands.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      );
    });

    const modelsOptions = computed<Array<OptionType<number>>>(() => {
      if (!values.value.brand || !values.value?.brand.value) {
        return [];
      }

      const brandData = params.value?.brands.find(
        (item) => item.id === values.value?.brand?.value
      );

      return brandData
        ? brandData.models.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        : [];
    });

    const generationsOptions = computed<Array<OptionType<number>>>(() => {
      if (!values.value.model || !values.value?.model.value) {
        return [];
      }

      const brandData = params.value?.brands.find(
        (item) => item.id === values.value?.brand?.value
      );

      if (!brandData) {
        return [];
      }

      const modelData = brandData.models.find(
        (item) => item.id === values.value?.model?.value
      );

      if (!modelData) return [];

      return modelData.generations.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    });

    const locationOptions = computed<Array<OptionType<number>>>(() => {
      const backendLocations = params.value?.locations || [];
      return [{ value: 0, label: "Без локации" }, ...backendLocations];
    });

    const bodyTypeOptions = computed<Array<OptionType>>(
      () => params.value?.bodyTypes || []
    );
    const fuelTypeOptions = computed<Array<OptionType>>(
      () => params.value?.fuelTypes || []
    );
    const gearboxOptions = computed<Array<OptionType>>(
      () => params.value?.gearboxes || []
    );
    const wheelOptions = computed<Array<OptionType>>(
      () => params.value?.wheels || []
    );
    const colorOptions = computed<Array<OptionType>>(
      () => params.value?.colors || []
    );
    const statusOptions = computed<Array<OptionType>>(
      () => params.value?.statuses || []
    );
    const stockStatusOptions = computed<Array<OptionType>>(
      () => params.value?.stockStatuses || []
    );
    const batteryOptions = computed<Array<OptionType>>(
      () => params.value?.batteries || []
    );

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
      getAmpCarFormValue(model.value, params.value)
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getAmpCarFormValue(model.value, params.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model, params], () => {
      updateFormValues();
    });

    const isElectro = computed<boolean>(() => {
      return values.value?.fuel?.value === "ELECTRIC";
    });

    const factoryOptionsField = ref<FieldUnion>(
      universalFieldUtils.createFormField(factoryOptionsFieldConfig, null)
    );

    function updateFactoryOptionsFields() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.equipment
        ? model.value?.equipment.map((value) => {
            return [
              {
                name: "section",
                value: value.section,
              },
              {
                name: "options",
                value:
                  value.options?.map((value) => {
                    return [
                      {
                        name: "name",
                        value: value.name,
                      },
                      {
                        name: "value",
                        value: value.value,
                      },
                    ];
                  }) || [],
              },
            ];
          })
        : [];

      factoryOptionsField.value = universalFieldUtils.createFormField(
        factoryOptionsFieldConfig,
        incomingFieldList
      ) as RepeaterField;
    }

    watch(model, () => updateFactoryOptionsFields());

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(
          values.value,
          factoryOptionsField.value as RepeaterField
        );

      const requestPromise = isCreation.value
        ? createPartnerCar(createOrUpdatePayload)
        : updatePartnerCar(parseInt(id?.value || ""), createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getPartnerCarsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getPartnerCarsUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getAmpCarFormValue(null, params.value);
          }

          toast.show({
            variant: "success",
            title: "Локации",
            body: isCreation.value
              ? "Автомобиль успешно создана"
              : "Автомобиль успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Локации",
            body: isCreation.value
              ? "Ошибка добавления автомобиля"
              : "Ошибка изменения автомобиля",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () =>
        isModelLoading.value ||
        isParamsLoading.value ||
        isGiftsOptionsLoading.value
    );

    const tabList = computed<Array<TabType>>(() => {
      const hasErrors = createTabErrorFinder(errors.value);

      return [
        {
          id: "common",
          label: "Основное",
          hasErrors: hasErrors([
            "brand",
            "model",
            "price",
            "year",
            "vin",
            "mileage",
          ]),
        },
        {
          id: "media",
          label: "Фото / Видео",
        },
        { id: "params", label: "Характеристики" },
        { id: "price", label: "Цена / Подарок" },
      ];
    });

    const selectedTabId = ref<string>(tabList.value[0].id);

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      isCreation,
      submitForm,
      backButtonUrl: getPartnerCarsUrl(),
      isSubmitting,

      locationOptions,
      brandsOptions,
      modelsOptions,
      generationsOptions,
      bodyTypeOptions,
      fuelTypeOptions,
      gearboxOptions,
      wheelOptions,
      colorOptions,
      statusOptions,
      stockStatusOptions,
      batteryOptions,

      tabList,
      selectedTabId,

      isElectro,
      factoryOptionsField,

      giftsOptions,

      currencyOptions: CURRENCY_OPTIONS,
      typeOptions: TYPE_OPTIONS,
      priceStrategyOptions: PRICE_STRATEGY_OPTIONS,
      priceChinaContainerOptions: PRICING_CHINA_CONTAINER_OPTIONS,
    };
  },
  computed: {
    PriceStrategy() {
      return PriceStrategy;
    },
  },
});
