import { request, ResponseBody } from "@tager/admin-services";

import { DealerInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getDealersCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/dealers/count" });
}

export function getDealers(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<DealerInterface>>> {
  return request.get({ path: "/admin/dealers", params });
}

export function getDealer(
  id: string | number
): Promise<ResponseBody<DealerInterface>> {
  return request.get({ path: `/admin/dealers/${id}` });
}

export type DealerUpdatePayload = {
  vinNewHidden: boolean;
  phonePublic: string;
};

export function updateDealer(
  id: number | string,
  payload: DealerUpdatePayload
): Promise<ResponseBody<DealerInterface>> {
  return request.put({
    path: `/admin/dealers/${id}`,
    body: payload,
  });
}
