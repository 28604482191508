
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import { FormFooter, TagerFormSubmitEvent, FormField } from "@tager/admin-ui";

import { getGiftsUpdateUrl, getGiftsUrl } from "../links";
import { createGift } from "../requests";

import {
  FormValues,
  convertFormValuesToCreationPayload,
  INITIAL_VALUES,
} from "./GiftsCreateForm.helpers";

export default defineComponent({
  name: "GiftsCreateForm",
  components: { Page, FormField, FormFooter },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(Object.assign({}, INITIAL_VALUES));

    const isSubmitting = ref<boolean>(false);

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      createGift(convertFormValuesToCreationPayload(values.value))
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getGiftsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getGiftsUrl());
          }

          if (event.type === "create_create-another") {
            values.value = Object.assign({}, INITIAL_VALUES);
          }

          toast.show({
            variant: "success",
            title: "Подарки",
            body: "Подарок успешно создан",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Подарки",
            body: "Ошибка создания подарка",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    return {
      values,
      errors,
      submitForm,
      backButtonUrl: getGiftsUrl(),
      isSubmitting,
    };
  },
});
