import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";

import NewCarsList from "./NewCarsList/NewCarsList.vue";
import NewCarsForm from "./NewCarsForm/NewCarsForm.vue";

export function getNewCarsUrl(): string {
  return "/cars/new";
}

export function getNewCarsUpdateUrl(id: number): string {
  return "/cars/new/" + id;
}

export const NEW_CARS_LIST_ROUTE: RouteRecordRaw = {
  path: "/cars/new",
  component: NewCarsList,
  name: "New Cars List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().CARS_NEW],
  },
};

export const NEW_CARS_FORM_ROUTE: RouteRecordRaw = {
  path: "/cars/new/:id",
  component: NewCarsForm,
  name: "New Car Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().CARS_NEW,
      { url: "", text: "Новый автомобиль" },
    ],
  },
};
