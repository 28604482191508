import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-adc5aeda"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "old-price"
}
const _hoisted_2 = {
  key: 0,
  class: "vin-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Новые авто" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, {
        "cell(price)": _withCtx(({ row }) => [
          _createElementVNode("div", null, [
            (row.discountPrice)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.formatMoney(row.price)), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(row.discountPrice
              ? _ctx.formatMoney(row.discountPrice)
              : _ctx.formatMoney(row.price)), 1)
          ])
        ]),
        "cell(vin)": _withCtx(({ row }) => [
          _createTextVNode(_toDisplayString(row.vin) + " ", 1),
          (row.vinHidden)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Скрыт"))
            : _createCommentVNode("", true)
        ]),
        "cell(status)": _withCtx(({ row }) => [
          _createTextVNode(_toDisplayString(_ctx.getStatusRaw(row.status)), 1)
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Редактировать",
            href: _ctx.getNewCarsUpdateUrl(row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["href"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }))
}