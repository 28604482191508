
import { computed, defineComponent } from "vue";

import {
  useDataTable,
  DataTable,
  BaseButton,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete } from "@tager/admin-services";

import { getGiftsCreateUrl, getGiftsUpdateUrl } from "@/modules/gifts/links";

import { getGifts, deleteGift } from "../requests";
import { GiftInterface } from "../typings";

import { COLUMN_DEFS } from "./GiftsList.helpers";

export default defineComponent({
  name: "GiftsList",
  components: {
    Page,
    DataTable,
    BaseButton,
    DeleteIcon,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<GiftInterface>({
      fetchEntityList: (params) =>
        getGifts({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список подарков",
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteGift,
      resourceName: "Подарок",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      isBusy,

      handleResourceDelete,

      getGiftsCreateUrl,
      getGiftsUpdateUrl,
    };
  },
});
