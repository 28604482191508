import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.isCreation ? 'Добавить Локацию' : 'Редактировать Локацию ID ' + _ctx.id,
    "is-content-loading": _ctx.isLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "Имя"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldMultiSelect, {
          "selected-options": _ctx.values.apiIds,
          "onUpdate:selected-options": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.apiIds) = $event)),
          name: "apiIds",
          error: _ctx.errors.apiIds,
          label: "Связь с API",
          options: _ctx.apiLocationsOptions
        }, null, 8, ["selected-options", "error", "options"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.phone,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.phone) = $event)),
          name: "phone",
          error: _ctx.errors.phone,
          label: "Телефон"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.address,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.address) = $event)),
          name: "address",
          error: _ctx.errors.address,
          label: "Адрес"
        }, null, 8, ["value", "error"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FormField, {
            value: _ctx.values.latitude,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.latitude) = $event)),
            name: "latitude",
            error: _ctx.errors.latitude,
            label: "Широта",
            type: "number"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormField, {
            value: _ctx.values.longitude,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.longitude) = $event)),
            name: "longitude",
            error: _ctx.errors.longitude,
            label: "Долгота",
            type: "number"
          }, null, 8, ["value", "error"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}