import { request, ResponseBody } from "@tager/admin-services";

import { GiftInterface, GiftFullInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getGiftsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/gifts/count" });
}

export function getGifts(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<GiftInterface>>> {
  return request.get({ path: "/admin/gifts", params });
}

export function getGift(
  id: string | number
): Promise<ResponseBody<GiftFullInterface>> {
  return request.get({ path: `/admin/gifts/${id}` });
}

export type GiftCreatePayload = {
  name: string;
  systemName: string;
};

export function createGift(
  payload: GiftCreatePayload
): Promise<ResponseBody<GiftFullInterface>> {
  return request.post({
    path: `/admin/gifts`,
    body: payload,
  });
}

export type GiftUpdatePayload = {
  name: string;
  systemName: string;
  generations: number[];
};

export function updateGift(
  id: number | string,
  payload: GiftUpdatePayload
): Promise<ResponseBody<GiftFullInterface>> {
  return request.put({
    path: `/admin/gifts/${id}`,
    body: payload,
  });
}

export function deleteGift(id: number | string): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/gifts/${id}` });
}

export type CatalogGeneration = {
  id: number;
  name: string;
};

export function getCatalogGenerations(): Promise<
  ResponseBody<Array<CatalogGeneration>>
> {
  return request.get({ path: "/admin/catalog/generations" });
}
