export function getGiftsUrl(): string {
  return "/gifts";
}

export function getGiftsCreateUrl(): string {
  return "/gifts/create";
}

export function getGiftsUpdateUrl(id: number): string {
  return "/gifts/" + id;
}
