
import { defineComponent } from "vue";

import {
  useDataTable,
  DataTable,
  BaseButton,
  SouthIcon,
  NorthIcon,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import {
  getColorsCreateUrl,
  getColorsUpdateUrl,
} from "@/modules/colors/routes";

import { deleteColor, getColors, moveColor } from "../requests";
import { ColorInterface } from "../typings";

import { COLUMN_DEFS } from "./ColorsList.helpers";

export default defineComponent({
  name: "ColorsList",
  components: {
    Page,
    DataTable,
    BaseButton,
    SouthIcon,
    NorthIcon,
    DeleteIcon,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ColorInterface>({
      fetchEntityList: (params) =>
        getColors({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список цветов",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveColor,
      resourceName: "Цвет",
      onSuccess: fetchEntityList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteColor,
      resourceName: "Цвет",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      isBusy,

      handleResourceMove,
      handleResourceDelete,

      getColorsCreateUrl,
      getColorsUpdateUrl,
    };
  },
});
