import { Nullable } from "@tager/admin-services";

import { ColorInterface } from "@/modules/colors/typings";
import { ColorCreateOrUpdatePayload } from "@/modules/colors/requests";

export type FormValues = {
  name: string;
  hex: string | null;
};

export function getColorFormValue(color: Nullable<ColorInterface>): FormValues {
  if (!color) {
    return {
      name: "",
      hex: null,
    };
  }

  return {
    name: color.name || "",
    hex: color.hex,
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): ColorCreateOrUpdatePayload {
  return {
    name: values.name,
    hex: values.hex || "",
  };
}
