import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";

import PartnerCarsList from "./PartnerCarsList";
import PartnerCarsForm from "./PartnerCarsForm";

export function getAmpCarsUrl(): string {
  return "/cars/amp";
}

export function getPartnerCarsUrl(): string {
  return "/cars/partner-cars";
}

export function getPartnerCarsCreateUrl(): string {
  return "/cars/partner-cars/create";
}

export function getPartnerCarsUpdateUrl(id: number): string {
  return "/cars/partner-cars/" + id;
}

export const PARTNER_CARS_LIST_ROUTE: RouteRecordRaw = {
  path: getPartnerCarsUrl(),
  component: PartnerCarsList,
  name: "Partner Cars List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().CARS_PARTNERS],
  },
};

export const PARTNER_CARS_FORM_ROUTE: RouteRecordRaw = {
  path: "/cars/partner-cars/:id",
  component: PartnerCarsForm,
  name: "Partner Car Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().CARS_PARTNERS,
      { url: "", text: "Автомобиль от партнера" },
    ],
  },
};
