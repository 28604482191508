
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  useDataTable,
  DataTable,
  AdvancedSearch,
  FormFieldMultiSelect,
  OptionType,
  getFilterParamAsStringArray,
  getFilterParams,
  FilterTagType,
  BaseButton,
  EditIcon,
  DeleteIcon,
  ContentCopyIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceClone, useResourceDelete } from "@tager/admin-services";

import { formatMoney } from "@/utils/common";

import {
  getStatusLabel,
  getStockStatusLabel,
  statusOptions,
} from "../constants";
import { clonePartnerCar, deletePartnerCar, getPartnerCars } from "../requests";
import { getPartnerCarsCreateUrl, getPartnerCarsUpdateUrl } from "../routes";
import { PartnerCarInterface } from "../typings";

import { COLUMN_DEFS, SORT_OPTIONS } from "./PartnerCarsList.helpers";

export default defineComponent({
  name: "AmpCarList",
  components: {
    Page,
    DataTable,
    AdvancedSearch,
    FormFieldMultiSelect,
    BaseButton,
    EditIcon,
    DeleteIcon,
    ContentCopyIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      fetchEntityList: fetchDataList,
      isLoading: isDataLoading,
      rowData: carsData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<PartnerCarInterface>({
      fetchEntityList: (params) =>
        getPartnerCars({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort || "",
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список бу авто",
      defaultSort: SORT_OPTIONS[0].value,
    });

    const initialStatusFilter = computed(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "status");
      return statusOptions.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const statusFilter = ref<Array<OptionType>>(initialStatusFilter.value);
    watch(initialStatusFilter, () => {
      statusFilter.value = initialStatusFilter.value;
    });

    const filterParams = computed(() => {
      return getFilterParams({
        status: statusFilter.value.map((item) => item.value),
      });
    });

    watch(filterParams, () => {
      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchDataList();
      }
    });

    function handleTagRemove(event: FilterTagType) {
      if (event.name === "status") {
        statusFilter.value = statusFilter.value.filter(
          (item) => item.value !== event.value
        );
      }
    }

    const tags = computed<Array<FilterTagType>>(() => [
      ...statusFilter.value.map((item) => ({
        value: item.value,
        label: item.label,
        name: "status",
        title: "Статус",
      })),
    ]);

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deletePartnerCar,
      resourceName: "Авто от партнера",
      onSuccess: fetchDataList,
    });

    const { isCloning, handleResourceClone } = useResourceClone({
      cloneResource: clonePartnerCar,
      confirmMessage: "Вы уверены, что хотите клонировать авто?",
      successMessage: "Авто успешно склонировано",
      failureMessage: "Ошибка клонирования авто",
      onSuccessRedirectTo: (data: PartnerCarInterface) =>
        "/cars/partner-cars/" + data.id,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isCloning(id) || isDataLoading.value;
    }

    const isRowDataLoading = computed<boolean>(() => isDataLoading.value);

    return {
      columnDefs: COLUMN_DEFS,
      rowData: carsData,
      isRowDataLoading: isRowDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      formatMoney,

      handleResourceDelete,
      handleResourceClone,
      isBusy,

      tags,
      handleTagRemove,
      statusFilterOptions: statusOptions,
      statusFilter,

      getPartnerCarsCreateUrl,
      getPartnerCarsUpdateUrl,

      sortOptions: SORT_OPTIONS,
      sortValue: sort,
    };
  },
  methods: { getStockStatusLabel, getStatusLabel },
});
