import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";

import ColorsList from "./ColorsList/ColorsList.vue";
import ColorsForm from "./ColorsForm/ColorsForm.vue";

export function getColorsUrl(): string {
  return "/colors";
}

export function getColorsCreateUrl(): string {
  return "/colors/create";
}

export function getColorsUpdateUrl(id: number): string {
  return "/colors/" + id;
}

export const COLORS_LIST_ROUTE: RouteRecordRaw = {
  path: "/colors",
  component: ColorsList,
  name: "Colors List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().COLORS],
  },
};

export const COLORS_FORM_ROUTE: RouteRecordRaw = {
  path: "/colors/:id",
  component: ColorsForm,
  name: "Colors Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().COLORS,
      { url: "", text: "Цвет" },
    ],
  },
};
