import { DealerInterface } from "@/modules/dealers/typings";
import { DealerUpdatePayload } from "@/modules/dealers/requests";

export type FormValues = {
  vinNewHidden: boolean;
  phonePublic: string;
};

export function getDealerFormValue(dealer: DealerInterface): FormValues {
  return {
    vinNewHidden: dealer.vinNewHidden,
    phonePublic: dealer.phonePublic,
  };
}

export function convertPageFormValuesToUpdatePayload(
  values: FormValues
): DealerUpdatePayload {
  return {
    vinNewHidden: values.vinNewHidden,
    phonePublic: values.phonePublic,
  };
}
