
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormFieldCheckbox,
  FieldValue,
  FormField,
} from "@tager/admin-ui";

import { DealerInterface } from "@/modules/dealers/typings";

import { getDealersUrl } from "../routes";
import { getDealer, updateDealer } from "../requests";

import {
  FormValues,
  convertPageFormValuesToUpdatePayload,
  getDealerFormValue,
} from "./DealersForm.helpers";

export default defineComponent({
  name: "CityForm",
  components: { Page, FormFieldCheckbox, FormFooter, FieldValue, FormField },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getDealer(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Dealer",
    });

    onMounted(() => {
      fetchModel();
    });
    watch(id, fetchModel);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>({ vinNewHidden: false, phonePublic: "" });

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      if (model.value) {
        values.value = getDealerFormValue(model.value as DealerInterface);
      }
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload = convertPageFormValuesToUpdatePayload(
        values.value as FormValues
      );

      const requestPromise = updateDealer(
        id.value || "",
        createOrUpdatePayload
      );

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "save_exit") {
            navigateBack(router, getDealersUrl());
          }

          toast.show({
            variant: "success",
            title: "Дилеры",
            body: isCreation.value
              ? "Дилер успешно создан"
              : "Дилер успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Дилеры",
            body: isCreation.value ? "Дилер не создан" : "Дилер не обновлен",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(() => isModelLoading.value);

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      isCreation,
      submitForm,
      backButtonUrl: getDealersUrl(),
      isSubmitting,
    };
  },
});
