import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";

import AmpLocationsList from "./AmpLocationsList/AmpLocationsList.vue";
import AmpLocationsForm from "./AmpLocationsForm/AmpLocationsForm.vue";

export function getAmpLocationsUrl(): string {
  return "/amp-locations";
}

export function getAmpLocationsCreateUrl(): string {
  return "/amp-locations/create";
}

export function getAmpLocationsUpdateUrl(id: number): string {
  return "/amp-locations/" + id;
}

export const AMP_LOCATIONS_LIST_ROUTE: RouteRecordRaw = {
  path: "/amp-locations",
  component: AmpLocationsList,
  name: "Amp Locations List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().AMP_LOCATIONS],
  },
};

export const AMP_LOCATIONS_FORM_ROUTE: RouteRecordRaw = {
  path: "/amp-locations/:id",
  component: AmpLocationsForm,
  name: "Amp Locations Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().AMP_LOCATIONS,
      { url: "", text: "Локация" },
    ],
  },
};
