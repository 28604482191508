import { computed, ComputedRef, onMounted } from "vue";

import { useResource } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import { getGifts } from "@/modules/gifts/requests";

type Return = {
  options: ComputedRef<Array<OptionType<number>>>;
  loading: ComputedRef<boolean>;
};

export const useGifts = (): Return => {
  const [fetchGifts, { data: gifts, loading }] = useResource({
    fetchResource: () => getGifts(),
    initialValue: null,
    resourceName: "Gifts",
  });

  onMounted(() => {
    fetchGifts();
  });

  const options = computed<Array<OptionType<number>>>(
    () =>
      gifts.value?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      }) || []
  );

  return {
    options,
    loading,
  };
};
