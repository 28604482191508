import { ColumnDefinition } from "@tager/admin-ui";

import { getDealersUpdateUrl } from "@/modules/dealers/routes";

import { DealerInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<DealerInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "API ID",
    field: "apiId",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 3,
    name: "Картинка",
    field: "image",
    type: "image",
  },
  {
    id: 4,
    field: "name",
    name: "Название",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: `${row.name}`,
        url: getDealersUpdateUrl(row.id),
      },
      paramList: [
        {
          name: "VIN спрятан",
          value: row.vinNewHidden ? "Да" : "Нет",
        },
      ],
    }),
  },
  {
    id: 5,
    name: "Адрес",
    field: "address",
  },
  {
    id: 6,
    name: "Телефон",
    field: "phonePublic",
  },
  {
    id: 7,
    name: "",
    field: "actions",
    style: { width: "40px", textAlign: "center" },
    headStyle: { width: "40px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
