import { createId, FileType, Nullable } from "@tager/admin-services";
import { SingleFileInputValueType } from "@tager/admin-ui";

export function getStatusRaw(status: string): string {
  switch (status) {
    case "AVAILABLE":
      return "Свободен";
    case "BOOKED":
      return "Забронирован";
    case "SOLD":
      return "Продан";
    default:
      return "Неизвестный";
  }
}

export function formatMoney(value: number): string {
  if (!value) return "-";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function createGalleryFileInputValue(
  fileInfo: Nullable<FileType>
): Nullable<SingleFileInputValueType> {
  return fileInfo
    ? {
        id: createId(),
        file: fileInfo,
        caption: "",
      }
    : null;
}
