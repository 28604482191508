
import { computed, defineComponent } from "vue";

import { useDataTable, DataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getCarsSyncSessions } from "../requests";
import { CarsSyncSessionInterface } from "../typings";

import { COLUMN_DEFS } from "./SyncSessionsList.helpers";

export default defineComponent({
  name: "SyncSessionsList",
  components: {
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchDataList,
      isLoading: isDataLoading,
      rowData: carsData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<CarsSyncSessionInterface>({
      fetchEntityList: (params) =>
        getCarsSyncSessions({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список синхронизаций",
    });

    const isRowDataLoading = computed<boolean>(() => isDataLoading.value);

    return {
      columnDefs: COLUMN_DEFS,
      rowData: carsData,
      isRowDataLoading: isRowDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
});
