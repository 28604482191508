import { ColumnDefinition } from "@tager/admin-ui";

import { GiftInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<GiftInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
  },
  {
    id: 2,
    name: "Системное имя",
    field: "systemName",
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "180px", textAlign: "center" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
