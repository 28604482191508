import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import {
  getCommandsListUrl,
  getCronLogsUrl,
  getCommandsLogsUrl,
} from "@tager/admin-cron";
import { getSettingItemListUrl } from "@tager/admin-settings";

import { getAmpLocationsUrl } from "@/modules/ampLocations/routes";
import { getAmpCarsUrl } from "@/modules/cars/amp/routes";
import { getPartnerCarsUrl } from "@/modules/cars/partner/routes";
import { getNewCarsUrl } from "@/modules/cars/new/routes";
import { getColorsUrl } from "@/modules/colors/routes";
import { getDealersUrl } from "@/modules/dealers/routes";
import { getCarsSyncSessionsUrl } from "@/modules/cars/sync-sessions";

type LinkKey =
  | "HOME"
  | "CARS_NEW"
  | "CARS_AMP"
  | "CARS_PARTNERS"
  | "CARS_SYNC_SESSIONS"
  | "AMP_LOCATIONS"
  | "DEALERS"
  | "SETTINGS_COMMON"
  | "COLORS"
  | "GIFTS"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS"
  | "CRON_COMMANDS"
  | "CRON_LOGS"
  | "CRON_COMMANDS_LOGS";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Главная",
  },
  GIFTS: {
    url: "/gifts",
    text: "Подарки",
  },
  CARS_SYNC_SESSIONS: {
    url: getCarsSyncSessionsUrl(),
    text: "Синхронизации",
  },
  CARS_NEW: {
    url: getNewCarsUrl(),
    text: "Новые авто",
  },
  CARS_AMP: {
    url: getAmpCarsUrl(),
    text: "Авто с пробегом",
  },
  CARS_PARTNERS: {
    url: getPartnerCarsUrl(),
    text: "Авто от партнера",
  },
  AMP_LOCATIONS: {
    url: getAmpLocationsUrl(),
    text: "Локации",
  },
  DEALERS: {
    url: getDealersUrl(),
    text: "Дилеры",
  },
  COLORS: {
    url: getColorsUrl(),
    text: "Цвета",
  },
  SETTINGS_COMMON: {
    url: getSettingItemListUrl(),
    text: "Настройки",
  },
  CRON_COMMANDS: {
    url: getCommandsListUrl(),
    text: "Команды",
  },
  CRON_COMMANDS_LOGS: {
    url: getCommandsLogsUrl(),
    text: "Команды - Логи",
  },
  CRON_LOGS: {
    url: getCronLogsUrl(),
    text: "Cron Логи",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
});
