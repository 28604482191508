import { ColumnDefinition } from "@tager/admin-ui";

import { CarsSyncSessionInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<CarsSyncSessionInterface>> = [
  {
    id: 0,
    name: "ID",
    field: "id",
    headStyle: { width: "60px" },
  },
  {
    id: 1,
    name: "Тип",
    field: "type",
    headStyle: { width: "60px" },
  },
  {
    id: 2,
    name: "Начало",
    field: "startAt",
    type: "datetime",
    headStyle: { width: "168px" },
  },
  {
    id: 3,
    name: "Конец",
    field: "finishAt",
    type: "datetime",
    headStyle: { width: "168px" },
  },
  {
    id: 4,
    name: "Результат",
    field: "",
    type: "key-value",
    options: {
      view: "table",
    },
    format: ({ row }) => {
      return [
        { key: "Машины: Синхронизировано", value: String(row.syncedCars) },
        { key: "Машины: Добавлено", value: String(row.addedCars) },
        { key: "Машины: Удалено", value: String(row.deletedCars) },
        { key: "Машины: Ошибки", value: String(row.failedCars) },
        { key: "Картинки: Добавлено", value: String(row.addedImages) },
        { key: "Картинки: Удалено", value: String(row.deletedImages) },
        { key: "Картинки: Ошибки", value: String(row.failedImages) },
      ];
    },
  },
  {
    id: 10,
    name: "Лог",
    field: "logFile",
    type: "file",
    width: "275px",
    headStyle: { textAlign: "center" },
  },
];
