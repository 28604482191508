
import { defineComponent } from "vue";

import { useDataTable, DataTable, EditIcon, BaseButton } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { formatMoney, getStatusRaw } from "@/utils/common";

import { getCarsNew } from "../requests";
import { NewCarInterface } from "../typings";
import { getNewCarsUpdateUrl } from "../routes";

import { COLUMN_DEFS } from "./NewCarsList.helpers";

export default defineComponent({
  name: "NewCarList",
  components: { BaseButton, EditIcon, Page, DataTable },
  setup() {
    const {
      isLoading: isNewCarLoading,
      rowData: newCarListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<NewCarInterface>({
      fetchEntityList: (params) =>
        getCarsNew({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список новых авто",
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: newCarListData,
      isRowDataLoading: isNewCarLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      formatMoney,
      getStatusRaw,
      getNewCarsUpdateUrl,
    };
  },
});
