import { OptionType } from "@tager/admin-ui";

export const statusOptions: Array<OptionType> = [
  { value: "AVAILABLE", label: "Свободен" },
  { value: "BOOKED", label: "Забронирован" },
  { value: "SOLD", label: "Продан" },
];

export const stockStatusOptions: Array<OptionType> = [
  { value: "ON_WAY", label: "В пути" },
  { value: "IN_PRODUCTION", label: "В производстве" },
  { value: "IN_STOCK", label: "В наличии" },
  { value: "PRE_ORDER", label: "Под заказ" },
];

export const TYPE_OPTIONS: Array<OptionType> = [
  {
    value: "new",
    label: "Новый автомобиль",
  },
  {
    value: "amp",
    label: "Автомобиль с пробегом",
  },
];

export function getStatusLabel(status: string): string {
  return (
    statusOptions.find((item) => item.value === status)?.label || "Неизвестный"
  );
}

export function getStockStatusLabel(status: string): string {
  return (
    stockStatusOptions.find((item) => item.value === status)?.label ||
    "Неизвестный"
  );
}

export function getTypeLabel(type: string): string {
  return (
    TYPE_OPTIONS.find((item) => item.value === type)?.label || "Неизвестный"
  );
}
