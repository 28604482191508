import { ColumnDefinition, OptionType } from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

import {
  getStatusLabel,
  getStockStatusLabel,
  getTypeLabel,
} from "../constants";
import { PartnerCarInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<PartnerCarInterface>> = [
  {
    id: 0,
    name: "ID",
    field: "id",
    headStyle: { width: "60px" },
  },
  {
    id: 1,
    name: "Автомобиль",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: `${row.name}`,
        url: null,
      },
      websiteLink: {
        text: row.url,
        url: row.url,
      },
      paramList: [
        {
          name: "Тип",
          value: getTypeLabel(row.type),
        },
        {
          name: "VIN",
          value: row.vin,
        },
        row.hidden
          ? {
              name: "Скрыт с сайта",
              value: "Да",
            }
          : null,
      ].filter(isNotNullish),
    }),
  },
  {
    id: 2,
    name: "Добавлен",
    field: "createdAt",
    type: "datetime",
    headStyle: { width: "160px" },
    style: { width: "160px", whiteSpace: "nowrap" },
  },
  {
    id: 3,
    name: "Наличие",
    field: "status",
    headStyle: { width: "120px", textAlign: "center" },
    style: { textAlign: "center" },
    format: ({ row }) => getStatusLabel(row.status),
  },
  {
    id: 4,
    name: "Склад",
    field: "stockStatus",
    headStyle: { width: "140px", textAlign: "center" },
    style: { textAlign: "center", whiteSpace: "nowrap" },
    format: ({ row }) => getStockStatusLabel(row.stockStatus),
  },
  {
    id: 5,
    name: "Цена",
    field: "price",
    style: { width: "120px", textAlign: "right", whiteSpace: "nowrap" },
    headStyle: { width: "120px", textAlign: "right", whiteSpace: "nowrap" },
  },
  {
    id: 6,
    name: "Действия",
    field: "actions",
    style: { textAlign: "center" },
    headStyle: { width: "150px", textAlign: "center" },
  },
];

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "created_at_desc",
    label: "Дата добавления: по убыванию",
  },
  {
    value: "created_at_asc",
    label: "Дата добавления: по возрастанию",
  },
  {
    value: "price_desc",
    label: "Цена: по убыванию",
  },
  {
    value: "price_asc",
    label: "Цена: по возрастанию",
  },
];
