
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  OptionType,
  FormFieldMultiSelect,
} from "@tager/admin-ui";

import { getGiftsUrl } from "../links";
import { getCatalogGenerations, getGift, updateGift } from "../requests";

import {
  FormValues,
  convertFormValuesToUpdatePayload,
  getFormValues,
} from "./GiftsUpdateForm.helpers";

export default defineComponent({
  name: "GiftsForm",
  components: { Page, FormField, FormFooter, FormFieldMultiSelect },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string);

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () =>
        id.value ? getGift(id.value) : Promise.resolve({ data: null }),
      initialValue: null,
      resourceName: "Подарок",
    });

    const [
      fetchCatalogGenerations,
      { data: catalogGenerations, loading: isCatalogGenerationsLoading },
    ] = useResource({
      fetchResource: () => getCatalogGenerations(),
      initialValue: null,
      resourceName: "Generations",
    });

    const catalogGenerationsOptions = computed<Array<OptionType<number>>>(
      () => {
        return (
          catalogGenerations.value?.map((item) => {
            return {
              value: item.id,
              label: "ID " + item.id + ": " + item.name,
            };
          }) || []
        );
      }
    );

    onMounted(() => {
      fetchModel();
      fetchCatalogGenerations();
      updateFormValues();
    });

    watch(id, fetchModel);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getFormValues(model.value, []));

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getFormValues(
        model.value,
        catalogGenerationsOptions.value
      );
    }

    watch([catalogGenerationsOptions, model], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      updateGift(id.value || "", convertFormValuesToUpdatePayload(values.value))
        .then((response) => {
          errors.value = {};

          if (event.type === "save_exit") {
            navigateBack(router, getGiftsUrl());
          }

          toast.show({
            variant: "success",
            title: "Подарки",
            body: "Подарок успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Подарки",
            body: "Ошибка обновления подарка",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isModelLoading.value || isCatalogGenerationsLoading.value
    );

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      submitForm,
      backButtonUrl: getGiftsUrl(),
      isSubmitting,
      catalogGenerationsOptions,
    };
  },
});
