import { OptionType } from "@tager/admin-ui";

import { GiftFullInterface } from "@/modules/gifts/typings";
import { GiftUpdatePayload } from "@/modules/gifts/requests";

export type FormValues = {
  name: string;
  systemName: string;
  generations: Array<OptionType<number>>;
};

export function getFormValues(
  gift: GiftFullInterface | null,
  generationsOptions: Array<OptionType<number>>
): FormValues {
  return {
    name: gift?.name || "",
    systemName: gift?.systemName || "",
    generations: generationsOptions.filter((item) =>
      gift?.generations.includes(item.value)
    ),
  };
}

export function convertFormValuesToUpdatePayload(
  values: FormValues
): GiftUpdatePayload {
  return {
    name: values.name,
    systemName: values.systemName,
    generations: values.generations.map((item) => item.value),
  };
}
