import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row-cols-2" }
const _hoisted_2 = { class: "row-cols-4" }
const _hoisted_3 = { class: "row-cols-2" }
const _hoisted_4 = { class: "row-cols-3" }
const _hoisted_5 = {
  key: 0,
  class: "row-cols-2"
}
const _hoisted_6 = { class: "row-cols-2" }
const _hoisted_7 = { class: "row-cols-2" }
const _hoisted_8 = {
  key: 1,
  class: "row-cols-2"
}
const _hoisted_9 = { class: "row-cols-2" }
const _hoisted_10 = { class: "row-cols-2" }
const _hoisted_11 = { class: "row-cols-2" }
const _hoisted_12 = { class: "row-cols-3" }
const _hoisted_13 = { class: "row-cols-2" }
const _hoisted_14 = {
  key: 0,
  class: "row-cols-3"
}
const _hoisted_15 = {
  key: 1,
  class: "row-cols-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_ToggleSection = _resolveComponent("ToggleSection")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tab-id": _cache[66] || (_cache[66] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: 
      _ctx.isCreation
        ? 'Добавить автомобиль от партнера'
        : 'Редактировать автомобиль от партнера ID ' + _ctx.id
    ,
    "is-content-loading": _ctx.isLoading,
    tabs: _ctx.tabList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[65] || (_cache[65] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", {
          style: _normalizeStyle({ display: _ctx.selectedTabId === 'common' ? 'block' : 'none' })
        }, [
          _createVNode(_component_FormFieldCheckbox, {
            checked: _ctx.values.hidden,
            "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.hidden) = $event)),
            name: "hidden",
            error: _ctx.errors.hidden,
            label: "Скрыт с сайта"
          }, null, 8, ["checked", "error"]),
          _createVNode(_component_FormFieldSelect, {
            value: _ctx.values.type,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.type) = $event)),
            name: "type",
            error: _ctx.errors.type,
            label: "Тип",
            options: _ctx.typeOptions
          }, null, 8, ["value", "error", "options"]),
          _createVNode(_component_FormFieldSelect, {
            value: _ctx.values.location,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.location) = $event)),
            name: "location",
            error: _ctx.errors.location,
            label: "Локация",
            options: _ctx.locationOptions
          }, null, 8, ["value", "error", "options"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_FormFieldSelect, {
              value: _ctx.values.status,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.status) = $event)),
              name: "status",
              error: _ctx.errors.status,
              label: "Статус",
              options: _ctx.statusOptions
            }, null, 8, ["value", "error", "options"]),
            _createVNode(_component_FormFieldSelect, {
              value: _ctx.values.stockStatus,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.stockStatus) = $event)),
              name: "stockStatus",
              error: _ctx.errors.stockStatus,
              label: "Складской статус",
              options: _ctx.stockStatusOptions
            }, null, 8, ["value", "error", "options"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FormFieldSelect, {
              value: _ctx.values.brand,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.brand) = $event)),
              name: "brand",
              error: _ctx.errors.brand,
              label: "Бренд",
              options: _ctx.brandsOptions
            }, null, 8, ["value", "error", "options"]),
            _createVNode(_component_FormFieldSelect, {
              value: _ctx.values.model,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.model) = $event)),
              name: "model",
              error: _ctx.errors.model,
              label: "Модель",
              options: _ctx.modelsOptions,
              disabled: !_ctx.values.brand
            }, null, 8, ["value", "error", "options", "disabled"]),
            _createVNode(_component_FormFieldSelect, {
              value: _ctx.values.generation,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.generation) = $event)),
              name: "generation",
              error: _ctx.errors.generation,
              label: "Поколение",
              options: _ctx.generationsOptions,
              disabled: !_ctx.values.model
            }, null, 8, ["value", "error", "options", "disabled"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.complectationName,
              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.complectationName) = $event)),
              name: "complectationName",
              error: _ctx.errors.complectationName,
              label: "Комплектация"
            }, null, 8, ["value", "error"])
          ]),
          _createVNode(_component_FormField, {
            value: _ctx.values.vin,
            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.values.vin) = $event)),
            name: "vin",
            error: _ctx.errors.vin,
            label: "VIN"
          }, null, 8, ["value", "error"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FormField, {
              value: _ctx.values.year,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.values.year) = $event)),
              name: "year",
              error: _ctx.errors.year,
              label: "Год",
              type: "number"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.mileage,
              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.values.mileage) = $event)),
              name: "mileage",
              error: _ctx.errors.mileage,
              label: "Пробег, км.",
              type: "number"
            }, null, 8, ["value", "error"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_FormFieldCheckbox, {
              checked: _ctx.values.onWarranty,
              "onUpdate:checked": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.values.onWarranty) = $event)),
              name: "onWarranty",
              error: _ctx.errors.onWarranty,
              label: "На гарантии"
            }, null, 8, ["checked", "error"]),
            _createVNode(_component_FormFieldCheckbox, {
              checked: _ctx.values.availableTestDrive,
              "onUpdate:checked": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.values.availableTestDrive) = $event)),
              name: "availableTestDrive",
              error: _ctx.errors.availableTestDrive,
              label: "Доступен тест-драйв"
            }, null, 8, ["checked", "error"]),
            _createVNode(_component_FormFieldCheckbox, {
              checked: _ctx.values.availableOnlineSale,
              "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.values.availableOnlineSale) = $event)),
              name: "availableOnlineSale",
              error: _ctx.errors.availableOnlineSale,
              label: "Доступна online продажа"
            }, null, 8, ["checked", "error"])
          ]),
          (_ctx.values.onWarranty)
            ? (_openBlock(), _createBlock(_component_FormField, {
                key: 0,
                value: _ctx.values.warrantyHint,
                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.values.warrantyHint) = $event)),
                name: "warrantyHint",
                label: "Подсказка беджика Гарантия"
              }, null, 8, ["value"]))
            : _createCommentVNode("", true)
        ], 4),
        _createElementVNode("div", {
          style: _normalizeStyle({ display: _ctx.selectedTabId === 'media' ? 'block' : 'none' })
        }, [
          _createVNode(_component_FormField, {
            value: _ctx.values.videoUrl,
            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.values.videoUrl) = $event)),
            name: "videoUrl",
            error: _ctx.errors.videoUrl,
            label: "Видео URL"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormFieldFileInput, {
            value: _ctx.values.photos,
            "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.values.photos) = $event)),
            name: "photos",
            error: _ctx.errors.photos,
            label: "Фотографии",
            multiple: true,
            "file-type": "image"
          }, null, 8, ["value", "error"])
        ], 4),
        _createElementVNode("div", {
          style: _normalizeStyle({ display: _ctx.selectedTabId === 'params' ? 'block' : 'none' })
        }, [
          _createVNode(_component_ToggleSection, { label: "Двигатель" }, {
            default: _withCtx(() => [
              _createVNode(_component_FormFieldSelect, {
                value: _ctx.values.fuel,
                "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.values.fuel) = $event)),
                name: "fuel",
                error: _ctx.errors.fuel,
                label: "Топливо",
                options: _ctx.fuelTypeOptions
              }, null, 8, ["value", "error", "options"]),
              (_ctx.isElectro)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_FormField, {
                      value: _ctx.values.batteryQuantity,
                      "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.values.batteryQuantity) = $event)),
                      name: "batteryQuantity",
                      error: _ctx.errors.batteryQuantity,
                      label: "Кол-во электродвигателей",
                      type: "number"
                    }, null, 8, ["value", "error"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.maxSpeed,
                  "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.values.maxSpeed) = $event)),
                  name: "maxSpeed",
                  error: _ctx.errors.maxSpeed,
                  label: "Максимальная скорость, км/ч",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.time0To100,
                  "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.values.time0To100) = $event)),
                  name: "time0To100",
                  error: _ctx.errors.time0To100,
                  label: "Время разгона до 100 км/ч, сек.",
                  type: "number"
                }, null, 8, ["value", "error"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.enginePower,
                  "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.values.enginePower) = $event)),
                  name: "enginePower",
                  error: _ctx.errors.enginePower,
                  label: "Максимальная мощность",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.engineMaxTorque,
                  "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.values.engineMaxTorque) = $event)),
                  name: "engineMaxTorque",
                  error: _ctx.errors.engineMaxTorque,
                  label: "Макс. крутящий момент",
                  type: "number"
                }, null, 8, ["value", "error"])
              ]),
              (_ctx.isElectro)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_FormField, {
                      value: _ctx.values.engine2Power,
                      "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.values.engine2Power) = $event)),
                      name: "engine2Power",
                      error: _ctx.errors.engine2Power,
                      label: "Двигатель 2 - Максимальная мощность",
                      type: "number"
                    }, null, 8, ["value", "error"]),
                    _createVNode(_component_FormField, {
                      value: _ctx.values.engine2MaxTorque,
                      "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.values.engine2MaxTorque) = $event)),
                      name: "engine2MaxTorque",
                      error: _ctx.errors.engine2MaxTorque,
                      label: "Двигатель 2 - Макс. крутящий момент",
                      type: "number"
                    }, null, 8, ["value", "error"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_ctx.isElectro)
            ? (_openBlock(), _createBlock(_component_ToggleSection, {
                key: 0,
                label: "Батарея"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormFieldSelect, {
                    value: _ctx.values.battery,
                    "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.values.battery) = $event)),
                    options: _ctx.batteryOptions,
                    searchable: false,
                    name: "battery",
                    error: _ctx.errors.battery,
                    label: "Тип батареи"
                  }, null, 8, ["value", "options", "error"]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_FormField, {
                      value: _ctx.values.batteryCapacity,
                      "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.values.batteryCapacity) = $event)),
                      name: "batteryCapacity",
                      error: _ctx.errors.batteryCapacity,
                      label: "Ёмкость батареи, кВт/ч",
                      type: "number"
                    }, null, 8, ["value", "error"]),
                    (_ctx.values.fuel && _ctx.isElectro)
                      ? (_openBlock(), _createBlock(_component_FormField, {
                          key: 0,
                          value: _ctx.values.distanceLimit,
                          "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.values.distanceLimit) = $event)),
                          name: "distanceLimit",
                          error: _ctx.errors.distanceLimit,
                          label: "Запас хода на электротяге, км.",
                          type: "number"
                        }, null, 8, ["value", "error"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_FormField, {
                      value: _ctx.values.chargeDcTime,
                      "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.values.chargeDcTime) = $event)),
                      name: "chargeDcTime",
                      error: _ctx.errors.chargeDcTime,
                      label: "Время зарядки постоянным током (DC), ч",
                      type: "number"
                    }, null, 8, ["value", "error"]),
                    _createVNode(_component_FormField, {
                      value: _ctx.values.chargeAcTime,
                      "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.values.chargeAcTime) = $event)),
                      name: "chargeAcTime",
                      error: _ctx.errors.chargeAcTime,
                      label: "Время зарядки переменным током (AC), ч",
                      type: "number"
                    }, null, 8, ["value", "error"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ToggleSection, { label: "Эксплуатационные характеристики" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.gearbox,
                  "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.values.gearbox) = $event)),
                  name: "gearbox",
                  error: _ctx.errors.gearbox,
                  label: "Коробка передач",
                  options: _ctx.gearboxOptions
                }, null, 8, ["value", "error", "options"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.wheel,
                  "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.values.wheel) = $event)),
                  name: "wheel",
                  error: _ctx.errors.wheel,
                  label: "Привод",
                  options: _ctx.wheelOptions
                }, null, 8, ["value", "error", "options"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.weight,
                  "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.values.weight) = $event)),
                  name: "weight",
                  error: _ctx.errors.weight,
                  label: "Снаряженная масса, кг",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.weightFull,
                  "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.values.weightFull) = $event)),
                  name: "weightFull",
                  error: _ctx.errors.weightFull,
                  label: "Максимально допустимая масса, кг",
                  type: "number"
                }, null, 8, ["value", "error"])
              ]),
              _createVNode(_component_FormField, {
                value: _ctx.values.warranty,
                "onUpdate:value": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.values.warranty) = $event)),
                name: "warranty",
                error: _ctx.errors.warranty,
                label: "Гарантия"
              }, null, 8, ["value", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_ToggleSection, { label: "Габариты авто" }, {
            default: _withCtx(() => [
              _createVNode(_component_FormFieldSelect, {
                value: _ctx.values.body,
                "onUpdate:value": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.values.body) = $event)),
                name: "body",
                error: _ctx.errors.body,
                label: "Тип кузова",
                options: _ctx.bodyTypeOptions
              }, null, 8, ["value", "error", "options"]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.length,
                  "onUpdate:value": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.values.length) = $event)),
                  name: "length",
                  error: _ctx.errors.length,
                  label: "Длина, мм",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.width,
                  "onUpdate:value": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.values.width) = $event)),
                  name: "width",
                  error: _ctx.errors.width,
                  label: "Ширина, мм",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.height,
                  "onUpdate:value": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.values.height) = $event)),
                  name: "height",
                  error: _ctx.errors.height,
                  label: "Высота, мм",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.clearance,
                  "onUpdate:value": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.values.clearance) = $event)),
                  name: "clearance",
                  error: _ctx.errors.clearance,
                  label: "Клиренс, мм",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.trunkVolume,
                  "onUpdate:value": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.values.trunkVolume) = $event)),
                  name: "trunkVolume",
                  error: _ctx.errors.trunkVolume,
                  label: "Объем багажника, л.",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.maxTrunkVolume,
                  "onUpdate:value": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.values.maxTrunkVolume) = $event)),
                  name: "maxTrunkVolume",
                  error: _ctx.errors.maxTrunkVolume,
                  label: "Максимальный объем багажника, л.",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.wheelBase,
                  "onUpdate:value": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.values.wheelBase) = $event)),
                  name: "wheelBase",
                  error: _ctx.errors.wheelBase,
                  label: "Колесная база, мм.",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.rotateDiameter,
                  "onUpdate:value": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.values.rotateDiameter) = $event)),
                  name: "rotateDiameter",
                  error: _ctx.errors.rotateDiameter,
                  label: "Диаметр разворота, м",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.trackFront,
                  "onUpdate:value": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.values.trackFront) = $event)),
                  name: "trackFront",
                  error: _ctx.errors.trackFront,
                  label: "Колея передняя, мм",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.trackBack,
                  "onUpdate:value": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.values.trackBack) = $event)),
                  name: "trackBack",
                  error: _ctx.errors.trackBack,
                  label: "Колея задняя, мм",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.doorsCount,
                  "onUpdate:value": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.values.doorsCount) = $event)),
                  name: "doorsCount",
                  error: _ctx.errors.doorsCount,
                  label: "Количество дверей",
                  type: "number"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.seatsCount,
                  "onUpdate:value": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.values.seatsCount) = $event)),
                  name: "seatsCount",
                  error: _ctx.errors.seatsCount,
                  label: "Количество мест",
                  type: "number"
                }, null, 8, ["value", "error"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_ToggleSection, { label: "Цвет" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.color,
                  "onUpdate:value": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.values.color) = $event)),
                  name: "color",
                  error: _ctx.errors.color,
                  label: "Цвет",
                  options: _ctx.colorOptions
                }, null, 8, ["value", "error", "options"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.colorInterior,
                  "onUpdate:value": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.values.colorInterior) = $event)),
                  name: "colorInterior",
                  error: _ctx.errors.colorInterior,
                  label: "Цвет интерьера",
                  options: _ctx.colorOptions
                }, null, 8, ["value", "error", "options"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_DynamicField, { field: _ctx.factoryOptionsField }, null, 8, ["field"]),
          _createVNode(_component_FormField, {
            value: _ctx.values.additional,
            "onUpdate:value": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.values.additional) = $event)),
            type: "textarea",
            name: "additional",
            error: _ctx.errors.additional,
            label: "Дополнительные опции"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormField, {
            value: _ctx.values.description,
            "onUpdate:value": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.values.description) = $event)),
            type: "textarea",
            name: "description",
            error: _ctx.errors.description,
            label: "Описание"
          }, null, 8, ["value", "error"])
        ], 4),
        _createElementVNode("div", {
          style: _normalizeStyle({ display: _ctx.selectedTabId === 'price' ? 'block' : 'none' })
        }, [
          _createVNode(_component_FormFieldCheckbox, {
            checked: _ctx.values.priceHidden,
            "onUpdate:checked": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.values.priceHidden) = $event)),
            name: "priceHidden",
            error: _ctx.errors.priceHidden,
            label: "Цена скрыта"
          }, null, 8, ["checked", "error"]),
          _createVNode(_component_FormFieldSelect, {
            value: _ctx.values.priceStrategy,
            "onUpdate:value": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.values.priceStrategy) = $event)),
            options: _ctx.priceStrategyOptions,
            name: "priceStrategy",
            error: _ctx.errors.priceStrategy,
            label: "Ценообразование"
          }, null, 8, ["value", "options", "error"]),
          (_ctx.values.priceStrategy.value === _ctx.PriceStrategy.Fixed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.priceFixedCurrency,
                  "onUpdate:value": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.values.priceFixedCurrency) = $event)),
                  name: "priceFixedCurrency",
                  error: _ctx.errors.priceFixedCurrency,
                  label: "Валюта",
                  options: _ctx.currencyOptions
                }, null, 8, ["value", "error", "options"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.priceFixedPrice,
                  "onUpdate:value": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.values.priceFixedPrice) = $event)),
                  name: "priceFixedPrice",
                  error: _ctx.errors.priceFixedPrice,
                  label: 
              _ctx.values.priceFixedCurrency?.value === 'USD'
                ? 'Цена, USD'
                : 'Цена, BYN'
            ,
                  type: "number"
                }, null, 8, ["value", "error", "label"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.priceFixedDiscountPrice,
                  "onUpdate:value": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.values.priceFixedDiscountPrice) = $event)),
                  name: "priceFixedCurrency",
                  error: _ctx.errors.priceFixedDiscountPrice,
                  label: 
              _ctx.values.priceFixedCurrency?.value === 'USD'
                ? 'Цена со скидкой, USD'
                : 'Цена со скидкой, BYN'
            ,
                  type: "number"
                }, null, 8, ["value", "error", "label"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.priceFixedTradeInPrice,
                  "onUpdate:value": _cache[58] || (_cache[58] = ($event: any) => ((_ctx.values.priceFixedTradeInPrice) = $event)),
                  name: "priceFixedTradeIn",
                  error: _ctx.errors.priceFixedTradeIn,
                  label: 
              _ctx.values.priceFixedCurrency?.value === 'USD'
                ? 'Trade-in Цена, USD'
                : 'Trade-in Цена, BYN'
            ,
                  type: "number"
                }, null, 8, ["value", "error", "label"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.values.priceStrategy.value === _ctx.PriceStrategy.China)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_FormField, {
                  value: _ctx.values.priceChinaRecommendedPrice,
                  "onUpdate:value": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.values.priceChinaRecommendedPrice) = $event)),
                  name: "priceChinaRecommendedPrice",
                  label: "Рекомендованная розничная цена, CNY",
                  type: "number"
                }, null, 8, ["value"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.priceChinaDiscountPrice,
                  "onUpdate:value": _cache[60] || (_cache[60] = ($event: any) => ((_ctx.values.priceChinaDiscountPrice) = $event)),
                  name: "priceChinaDiscountPrice",
                  label: "Акционная цена, CNY",
                  type: "number"
                }, null, 8, ["value"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.priceChinaTradeInPrice,
                  "onUpdate:value": _cache[61] || (_cache[61] = ($event: any) => ((_ctx.values.priceChinaTradeInPrice) = $event)),
                  name: "priceChinaTradeInPrice",
                  label: "Trade-In Цена, CNY",
                  type: "number"
                }, null, 8, ["value"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.priceChinaProfit,
                  "onUpdate:value": _cache[62] || (_cache[62] = ($event: any) => ((_ctx.values.priceChinaProfit) = $event)),
                  name: "priceChinaProfit",
                  label: "Наценка, CNY",
                  type: "number"
                }, null, 8, ["value"]),
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.values.priceChinaContainer,
                  "onUpdate:value": _cache[63] || (_cache[63] = ($event: any) => ((_ctx.values.priceChinaContainer) = $event)),
                  name: "priceChinaContainer",
                  error: _ctx.errors.priceChinaContainer,
                  label: "Контейнер",
                  options: _ctx.priceChinaContainerOptions,
                  searchable: false,
                  clearable: false
                }, null, 8, ["value", "error", "options"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_FormFieldSelect, {
            value: _ctx.values.gift,
            "onUpdate:value": _cache[64] || (_cache[64] = ($event: any) => ((_ctx.values.gift) = $event)),
            name: "gift",
            error: _ctx.errors.gift,
            label: "Подарок",
            options: _ctx.giftsOptions,
            searchable: false
          }, null, 8, ["value", "error", "options"])
        ], 4)
      ], 32)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}