import { ColumnDefinition } from "@tager/admin-ui";

import { AmpLocationInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<AmpLocationInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
  },
  {
    id: 3,
    name: "Адрес",
    field: "address",
  },
  {
    id: 4,
    name: "Телефон",
    field: "phone",
  },
  {
    id: 5,
    name: "Связь с API",
    field: "apiIds",
    format: ({ row }) =>
      row.apiIds && Array.isArray(row.apiIds) ? row.apiIds.join(", ") : "",
    headStyle: { width: "120px" },
  },
  {
    id: 6,
    name: "",
    field: "relations",
    headStyle: { width: "280px" },
  },
  {
    id: 7,
    name: "Действия",
    field: "actions",
    style: { width: "180px", textAlign: "center" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
