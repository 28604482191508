import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import { NewCarFullInterface } from "../typings";
import { NewCarSavePayload } from "../typings";

export type FormValues = {
  hidden: boolean;
  gift: Nullable<OptionType<number>>;
};

export function getNewCarsFormValues(
  car: Nullable<NewCarFullInterface>
): FormValues {
  if (!car) {
    return {
      hidden: false,
      gift: null,
    };
  }

  return {
    hidden: car.hidden,
    gift: car.gift
      ? {
          value: car.gift.id,
          label: car.gift.name,
        }
      : null,
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): NewCarSavePayload {
  return {
    hidden: values.hidden,
    gift: values.gift?.value || null,
  };
}
