import { GiftCreatePayload } from "@/modules/gifts/requests";

export type FormValues = {
  name: string;
  systemName: string;
};

export const INITIAL_VALUES: FormValues = {
  name: "",
  systemName: "",
};

export function convertFormValuesToCreationPayload(
  values: FormValues
): GiftCreatePayload {
  return {
    name: values.name,
    systemName: values.systemName,
  };
}
