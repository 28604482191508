
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  OptionType,
  FormFieldMultiSelect,
} from "@tager/admin-ui";

import { getAmpLocationsUrl, getAmpLocationsUpdateUrl } from "../routes";
import {
  createAmpLocation,
  getAmpLocation,
  getApiAmpLocations,
  updateAmpLocation,
} from "../requests";

import {
  FormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
  getAmpLocationFormValue,
} from "./AmpLocationsForm.helpers";

export default defineComponent({
  name: "CityForm",
  components: { Page, FormField, FormFooter, FormFieldMultiSelect },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getAmpLocation(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "AMP Location",
    });

    const [
      fetchApiLocationsList,
      { data: apiLocations, loading: isApiLocationsLoading },
    ] = useResource({
      fetchResource: () => getApiAmpLocations(),
      initialValue: [],
      resourceName: "Api Locations",
    });

    onMounted(() => {
      fetchApiLocationsList();
      fetchModel();
    });
    watch(id, fetchModel);

    const apiLocationsOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...apiLocations.value.map((item) => ({
          value: item.id,
          label: item.label,
        })),
      ];
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
      getAmpLocationFormValue(model.value, apiLocationsOptions.value)
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getAmpLocationFormValue(
        model.value,
        apiLocationsOptions.value
      );
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model, apiLocations], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(values.value);

      const requestPromise = isCreation.value
        ? createAmpLocation(createOrUpdatePayload)
        : updateAmpLocation(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getAmpLocationsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getAmpLocationsUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getAmpLocationFormValue(
              null,
              apiLocationsOptions.value
            );
          }

          toast.show({
            variant: "success",
            title: "Локации",
            body: isCreation.value
              ? "Локация успешно создана"
              : "Локация успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Локации",
            body: isCreation.value
              ? "Ошибка добавления Локации"
              : "Ошибка изменения Локации",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isModelLoading.value || isApiLocationsLoading.value
    );

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      isCreation,
      submitForm,
      backButtonUrl: getAmpLocationsUrl(),
      isSubmitting,
      apiLocationsOptions,
    };
  },
});
