import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import { AmpLocationInterface } from "@/modules/ampLocations/typings";
import { AmpLocationCreateOrUpdatePayload } from "@/modules/ampLocations/requests";

export type FormValues = {
  name: string;
  phone: string;
  address: string;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
  apiIds: Array<OptionType<number>>;
};

export function getAmpLocationFormValue(
  ampLocation: Nullable<AmpLocationInterface>,
  apiLocations: Array<OptionType<number>>
): FormValues {
  if (!ampLocation) {
    return {
      name: "",
      phone: "",
      address: "",
      latitude: null,
      longitude: null,
      apiIds: [],
    };
  }

  return {
    name: ampLocation.name || "",
    phone: ampLocation.phone,
    address: ampLocation.address,
    latitude: ampLocation.latitude,
    longitude: ampLocation.longitude,
    apiIds: apiLocations.filter((item) =>
      ampLocation.apiIds.includes(item.value)
    ),
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): AmpLocationCreateOrUpdatePayload {
  return {
    name: values.name,
    phone: values.phone,
    address: values.address,
    latitude: values.latitude || null,
    longitude: values.longitude || null,
    apiIds: values.apiIds.map((item) => item.value),
  };
}
