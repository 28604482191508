import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import AmpLocationsList from "@/modules/dealers/DealersList/DealersList.vue";
import AmpLocationsForm from "@/modules/dealers/DealersForm/DealersForm.vue";

export function getDealersUrl(): string {
  return "/dealers";
}

export function getDealersUpdateUrl(id: number): string {
  return "/dealers/" + id;
}

export const DEALERS_LIST_ROUTE: RouteRecordRaw = {
  path: "/dealers",
  component: AmpLocationsList,
  name: "Dealers List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().DEALERS],
  },
};

export const DEALERS_FORM_ROUTE: RouteRecordRaw = {
  path: "/dealers/:id",
  component: AmpLocationsForm,
  name: "Dealer Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().DEALERS,
      { url: "", text: "Дилер" },
    ],
  },
};
