import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import GiftsList from "@/modules/gifts/GiftsList/GiftsList.vue";
import GiftsCreateForm from "@/modules/gifts/GiftsCreateForm/GiftsCreateForm.vue";
import GiftsUpdateForm from "@/modules/gifts/GiftsUpdateForm/GiftsUpdateForm.vue";

export const GIFTS_LIST_ROUTE: RouteRecordRaw = {
  path: "/gifts",
  component: GiftsList,
  name: "Gifts List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().GIFTS],
  },
};

export const GIFTS_CREATE_FORM_ROUTE: RouteRecordRaw = {
  path: "/gifts/create",
  component: GiftsCreateForm,
  name: "Gifts Create Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().GIFTS,
      { url: "", text: "Новый подарок" },
    ],
  },
};

export const GIFTS_UPDATE_FORM_ROUTE: RouteRecordRaw = {
  path: "/gifts/:id",
  component: GiftsUpdateForm,
  name: "Gifts Update Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().GIFTS,
      { url: "", text: "Редактировать подарок" },
    ],
  },
};
