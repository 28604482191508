
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldColorInput,
  OptionType,
  FormFieldMultiSelect,
} from "@tager/admin-ui";

import { getColorsUpdateUrl, getColorsUrl } from "../routes";
import { createColor, getColor, updateColor } from "../requests";

import {
  FormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
  getColorFormValue,
} from "./ColorsForm.helpers";

export default defineComponent({
  name: "ColorsForm",
  components: { Page, FormField, FormFooter, FormFieldColorInput },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getColor(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Color",
    });

    onMounted(() => {
      fetchModel();
    });
    watch(id, fetchModel);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(getColorFormValue(model.value));

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getColorFormValue(model.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(values.value);

      const requestPromise = isCreation.value
        ? createColor(createOrUpdatePayload)
        : updateColor(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getColorsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getColorsUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getColorFormValue(null);
          }

          toast.show({
            variant: "success",
            title: "Цвета",
            body: isCreation.value
              ? "Цвет успешно создан"
              : "Цвет успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Цвета",
            body: isCreation.value
              ? "Ошибка добавления цвета"
              : "Ошибка изменения цвета",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(() => isModelLoading.value);

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      isCreation,
      submitForm,
      backButtonUrl: getColorsUrl(),
      isSubmitting,
    };
  },
});
